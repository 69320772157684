.sidebar {
  background-color: $sidebar-color;
  color: rgb(255, 255, 255);
  color: rgba(255, 255, 255, 0.5);
  padding: 2rem 0.5rem;
  text-align: center;

  li {
    overflow: hidden;
  }

  a {
    color: $gray-1;
    border: none;

    // fixes rendering of inline svg used to avoid adblocked icons
    svg:not(:root).svg-inline--fa {
      overflow: visible;
    }

    .svg-inline--fa.fa-w-16 {
      width: 1em;
    }

    .svg-inline--fa {
      display: inline-block;
      font-size: inherit;
      height: 1em;
      overflow: visible;
      vertical-align: -.125em;
    }

    .fa-icon {
      width: 1.5rem;
      padding-right: 0.5rem;
      float: left;
    }

    code {
      float: left;
      max-width: 85%;
      color: $gray-1;

      &:hover {
        color: $sidebar-link-color;

        em {
          color: $sidebar-link-color;
        }
      }

      &:focus {
        color: $sidebar-link-color;
      }

      em {
        font-weight: bold;
        color: $gray-4;
      }
    }

    &:hover {
      color: $sidebar-link-color;
    }

    &:focus {
      color: $sidebar-link-color;
    }
  }

  .sidebar-about {
    text-align: center;
  }

  .author-image {
    display: block;
    margin-top: 4px;
  }

  .container.fixed-container {
    position: absolute;
    bottom: .3rem;
  }
}

.sidebar-nav {
  text-align: left;
  list-style: none;
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding-left: 0;
}

.sidebar-nav-item {
  display: block;
  line-height: 1.75;

  .active {
    font-weight: bold;
  }
}

.site__title {
  font-size: $site__title-font-size;
  margin-bottom: 0.5rem;

  a:hover {
    border: none;
  }
}

.site__description {
  font-size: 1.285rem;
  font-weight: 300;
}

.social {
  text-align: center;

  a {
    padding: 0 4px;
    @include link-no-decoration();
  }
}

.img--circle {
  border-radius: 50%;
}

.img--headshot {
  height: 115px;
  width: 115px;
}

.img--caption {
  font-style: italic;
}

%small-center-text {
  font-size: $font-scale-dot7;
  line-height: 1.1rem;
  text-align: center;
}

.copyright {
  padding-top: 1rem;
  @extend %small-center-text;
}

.builtwith {
  padding-top: .2rem;
  @extend %small-center-text;
}
