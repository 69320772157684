// TODO add shadow on horizontal scrollable content https://github.com/kizu/kizu.ru/blob/source/src/posts/2012-04-21-shadowscroll/shadowscroll.html

// mobile ~320..480px
// 320px ~ 16rem/16px ~ 18.8235/17px ~ 17.7778/18px
// 480px ~ 30rem/16px ~ 28.2353rem/17px ~ 26.6667rem/18px
@media (min-width: 320px) and (max-width: 767px) {
  html,
  body {
    font-size: $small-device-font-size;
  }
  .sidebar {
    li {
      padding: .1rem 1rem 0;
    }

    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .hidden-tablet {
    display: none;
  }
  .sidebar-about,
  .copyright {
    display: none;
  }
  .sidebar {
    margin: 0;
    padding: 0;
  }
  .menu-content {
    padding: 0 0 0 0px;
    max-height: 0;
    overflow: hidden;
    margin-top: 0;
  }
  .collapsible-menu {
    padding: 0px 0px;
  }
  .collapsible-menu ul {
    list-style-type: none;
    padding: 0;
  }
  .collapsible-menu li {
    display: block;
  }
  .collapsible-menu a {
    text-decoration: none;
    cursor: pointer;
  }
  .collapsible-menu label {
    background: url(/img/menu-open.svg) no-repeat left center;
    background-position: 1.5rem;
    display: block;
    cursor: pointer;
    color: #fff;
    padding: 10px 0 10px 0px;
  }

  input#menuToggle {
    display: none;
  }
  input#menuToggle + label {
    font-weight: bold;
  }

  input#menuToggle:checked + label {
    background-image: url(/img/menu-close.svg);
    background-position: 1.5rem;
    color: #fff;
  }

  input#menuToggle:checked ~ .menu-content {
    max-height: 100% !important;
  }
  .social {
    padding: 1em 0 1em 0;
  }

  .post.adoc {
    .colist {
      overflow-x: auto;
    }

    .admonitionblock {
      > table {
        td.icon {
          display: block;
          position: sticky;
          top: 0;
          width: unset !important;
          border-bottom: 2px solid $admonition-default-border-color;


          [class^="fa icon-"] {
            font-size: 1.5rem;
          }
        }

        td.content {
          display: block;
        }
      }

      &.important {
        // hyde-hyde fix : remove border
        border-right: unset;

        > table td.icon {
          border-bottom: 2px solid $admonition-icon-important-border-color;
        }
      }

      &.warning {
        // hyde-hyde fix : remove border
        border-right: unset;

        > table td.icon {
          border-bottom: 2px solid $admonition-icon-warning-border-color;
        }
      }

      &.caution {
        > table td.icon {
          border-bottom: 2px solid $admonition-icon-caution-border-color;
        }
      }
    }
  }
}

// tablet/medium device ~768px+
// 768px ~ 48em/16px ~ 45.1765em/17px ~ 42.6667/18px
@media (min-width: 768px) {
  html,
  body {
    font-size: $small-device-font-size;
  }
  .sidebar {
    bottom: 0;
    left: 0;
    position: fixed;
    text-align: left;
    top: 0;
    width: $sidebar-width;
  }
  .sidebar-sticky {
    left: 1rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
  .content {
    margin-left: $content-margin-left;
    margin-right: $content-margin-right;
    max-width: $content-max-width;
  }
  .layout-reverse {
    .sidebar {
      left: auto;
      right: 0;
    }

    .content {
      margin-left: $content-margin-right;
      margin-right: $content-margin-left;
    }
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .project__title {
    margin-top: 0.2rem;
  }
  .navigation {
    display: flex;
    justify-content: space-between;

    a {
      width: calc(50% - 10px);

      &:nth-child(2) {
        margin-top: 0;
      }
    }
  }
  input#menuToggle,
  input#menuToggle + label {
    display: none;
  }
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) /* iPad mini in landscape */
{
  .sidebar-about {
    text-align: left !important;

    .site__title {
      float: left;
      font-size: 1.3rem;
      width: 150px;
    }

    .author-image {
      display: inline-block;
      width: 60px;
      height: 60px;

      .img--headshot {
        width: 60px;
        height: 60px;
      }
    }

    .site__description {
      font-size: 0.8rem;
    }

  }
}


// Large devices (laptops/desktops, 992px and up)
// ~ 62rem/16px ~ 58.3529rem/17px ~ 55.1111rem/18px
@media (min-width: 992px) {
  html,
  body {
    font-size: $large-device-font-size;
  }
  .layout-reverse .content {
    margin-left: 4rem;
    margin-right: 22rem;
  }
  .col-md-4 {
    float: left;
    width: 33.33333333%;
  }
  .col-md-8 {
    float: left;
    width: 66.66666667%;
  }
  .portfolio-container {
    width: 68rem;
  }
}

// Large device ~1024px
// 1024px ~  64rem/16px ~ 60.2353rem/17px 56.8889rem/18px
@media (min-width: 1024px) {
  // styles
}


// Source https://websiteedu.com/media-queries-for-mobile-and-tablets/
// Tweak sidebar for wide enough phone in landscape
@media only screen and (min-width: 741px) and (max-width: 896px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {

  //@media only screen /* and (min-device-width: 375px) */ and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) /* iPhone 11 in landscape */,
  //       only screen /* and (min-device-width: 375px) */ and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) /* iPhone X, 11 Pro in landscape */,
  //       only screen /* and (min-device-width: 414px) */ and (max-device-width: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) /* iPhone Xr, 11 in landscape */,
  //       only screen /* and (min-device-width: 414px) */ and (max-device-width: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) /* iPhone Xs, 11 Pro Max */,
  //       only screen /* and (min-device-width: 412px) */ and (max-device-width: 870px) and (-webkit-min-device-pixel-ratio: 2.6) /* Pixel 3, Pixel 4 */,
  //       only screen /* and (min-device-width: 412px) */ and (max-device-width: 870px) and (-webkit-min-device-pixel-ratio: 3.5) /* Pixel 2 XL, Pixel 3 XL, Pixel 4 XL */ {
  .sidebar {
    width: 13rem;
    font-size: 0.9rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;

    .container {
      padding-left: 0.2rem;
      padding-right: 0.2rem;

      .collapsible-menu {
        margin-top: -1.5rem;
      }
    }

    .sidebar-about {
      .site__title {
        float: unset;
        font-size: 1.1rem;
      }

      .author-image {
        display: none;
      }

      .site__description {
        display: none;
      }
    }

    .copyright {
      font-size: .5rem
    }
  }

  .content {
    margin-left: 13rem;
    margin-right: 1rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
}

// Move sidebar on the top for non wide enough phones
@media only screen and (min-width: 360px) and (max-width: 740px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  //@media only screen and (min-device-width: 360px) and (max-device-width: 740px) and (-webkit-min-device-pixel-ratio: 4) and (orientation: landscape) /* Galaxy S8, S8+, S9, S9+, S10, S10+ */,
  //       only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) /* iPhone 6, 6S, 7, 8 in landscape */,
  //       only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) /* iPhone 6 Plus, 6S Plus, 7 Plus, 8 Plus in landscape */ {
  .sidebar {
    width: inherit;
    font-size: 1rem;
  }
  .content {
    margin-left: inherit;
    margin-right: inherit;
    padding-bottom: inherit;
    padding-top: inherit;
  }
}

// Tweak side bar when browser height is small
@media only screen and (min-height: 543px) and (max-height: 740px) {
  .sidebar {
    //width: 13rem;
    //font-size: 0.9rem;
    //padding-top: 0.3rem;
    //padding-bottom: 0.3rem;

    //.container{
    //  padding-left: 0.2rem;
    //  padding-right: 0.2rem;
    //  .collapsible-menu {
    //    margin-top: -1.5rem;
    //  }
    //}
    .sidebar-about {
      .site__title {
        //float: unset;
        //font-size: 1.1rem;
      }

      .author-image {
        display: none;
      }

      .site__description {
        display: none;
      }
    }

    //.copyright { font-size: .5rem}
  }
}

@media only screen and (max-height: 542px) {
  .sidebar {
    width: 13rem;
    font-size: 0.9rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;

    .container {
      padding-left: 0.2rem;
      padding-right: 0.2rem;

      .collapsible-menu {
        margin-top: -1.5rem;
      }
    }

    .sidebar-about {
      .site__title {
        float: unset;
        font-size: 1.1rem;
      }

      .author-image {
        display: none;
      }

      .site__description {
        display: none;
      }
    }

    .copyright {
      font-size: .5rem
    }
  }
}